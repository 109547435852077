import React from 'react';
import { BarChart, Bar } from 'recharts';

export default function MultiColorProgressBar({ data, className }) {
  return (
    <BarChart className={className} width={94} height={240} data={[data]} margin={0} barCategoryGap={0}>
      <Bar dataKey="disbursed" stackId="a" fill="#FEFDBF" stroke="#AAAAAA" strokeWidth={2} />
      <Bar dataKey="availableSupply" stackId="a" fill="#E9E7E4" />
    </BarChart>
  );
};
