import React, { useState, useEffect, useRef } from 'react';

import Button from '../Button';
import Dropdown from '../Dropdown';
import Label from '../Label';
import Tab from '../Tab';
import Title from '../Title';
import {
  ChevronLeftIcon,
  ChevronLeftActiveIcon,
  ChevronRightIcon,
  ChevronRightActiveIcon,
  CloseBlackIcon,
  BlackDetailIcon,
} from '../../assets/svgs';
import { countries, regions, sorts } from '../../constants';

const tabList = ['All', 'Engineering', 'Design', 'Product', 'Other'];

export default function AverageMarketRates(props) {
  const { data } = props;
  const [selectedTab, setSelectedTab] = useState('Engineering');
  const [currentPage, setCurrentPage] = useState(1);
  const [openRegion, setOpenRegion] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [showIntro, setShowIntro] = useState(false);
  const [filters, setFilters] = useState({
    region: 'world',
    sort: 'high-to-low',
  });

  const pageSize = 9;
  const totalPage = Math.ceil(filteredData.length / pageSize);
  const currentData = filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const regionRef = useRef();
  const sortRef = useRef();

  const handleClickOutside = (event) => {
    if (openRegion && regionRef && !regionRef.current.contains(event.target)) setOpenRegion(false);
    if (openSort && sortRef && !sortRef.current.contains(event.target)) setOpenSort(false);
  };

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPage) setCurrentPage(currentPage + 1);
  };

  const handleTabClick = (value) => {
    setCurrentPage(1);
    setSelectedTab(value);
  };

  const onFilterItemClick = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const Pagination = () => (
    <div className="dashboard__leaderboard__pagination">
      <Button
        styles="dashboard__leaderboard__pagination__btn-prev"
        disabled={currentPage === 1}
        onClick={handlePrev}>
        {currentPage === 1 ? (
          <ChevronLeftIcon />
        ) : (
          <ChevronLeftActiveIcon />
        )}
      </Button>
      <Label type="primary" size="small">
        Page {currentPage} of {totalPage}
      </Label>
      <Button
        styles="dashboard__leaderboard__pagination__btn-next"
        disabled={currentPage === totalPage}
        onClick={handleNext}>
        {currentPage === totalPage ? (
          <ChevronRightIcon />
        ) : (
          <ChevronRightActiveIcon />
        )}
      </Button>
    </div>
  );

  useEffect(() => {
    let result = [];
    if (selectedTab === 'Engineering') {
      result = data.filter(item => item.root_role === 'Engineer');
    } else if (selectedTab === 'Design') {
      result = data.filter(item => item.root_role === 'Designer');
    } else if (selectedTab === 'Product') {
      result = data.filter(item => item.root_role === 'Product Manager');
    } else if (selectedTab === 'Other') {
      result = data.filter(item => item.root_role !== 'Engineer' && item.root_role !== 'Designer' && item.root_role !== 'Product Manager');
    } else {
      result = data;
    }

    if (filters.region === 'north-america') {
      let temp = [];
      result.forEach(item => {
        const country = countries.find(data => data.country === item.location);
        if (country && country.region === 'NA') temp.push(item);
      });
      result = temp;
    }

    if (filters.sort === 'a-z') {
      result = result.sort((a, b) => a.name.localeCompare(b.name));
    } else if (filters.sort === 'z-a') {
      result = result.sort((a, b) => b.name.localeCompare(a.name));
    } else if (filters.sort === 'high-to-low') {
      result = result.sort((a, b) => {
        let sumRate = 0;
        for (var i = 0; i < b.rate.length; i ++) {
          sumRate += parseFloat(b.rate[i].amount);
        };
        const bAvgRate = sumRate / b.rate.length;
        sumRate = 0;
        for (i = 0; i < a.rate.length; i ++) {
          sumRate += parseFloat(a.rate[i].amount);
        };
        const aAvgRate = sumRate / a.rate.length;
        return bAvgRate - aAvgRate;
      });
    } else {
      result = result.sort((a, b) => {
        let sumRate = 0;
        for (var i = 0; i < b.rate.length; i ++) {
          sumRate += parseFloat(b.rate[i].amount);
        };
        const bAvgRate = sumRate / b.rate.length;
        sumRate = 0;
        for (i = 0; i < a.rate.length; i ++) {
          sumRate += parseFloat(a.rate[i].amount);
        };
        const aAvgRate = sumRate / a.rate.length;
        return aAvgRate - bAvgRate;
      });
    }

    setFilteredData([...result]);
  }, [selectedTab, filters, data]);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    }
  }, [openRegion, openSort]);

  return (
    <div
      className="dashboard__leaderboard__rates"
      data-aos="fade-up"
      data-aos-delay="50">
      <div
        className={`dashboard__leaderboard__rates__main ${`dashboard__leaderboard__rates__main__${showIntro ? 'hide' : 'show'}`}`}>
        <div className="dashboard__leaderboard__rates__header">
          <Title type="primary" size="medium" font="medium">
            Average market rates.
          </Title>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__leaderboard__rates__content">
          <div
            className="dashboard__leaderboard__rates__content__tabs">
            <Tab
              list={tabList}
              selected={selectedTab}
              onTabItemClick={handleTabClick}
            />
          </div>
          <div
            className="dashboard__leaderboard__rates__content__filter">
            <div
              className="dashboard__leaderboard__rates__content__filter__left">
              <Dropdown
                open={openRegion}
                selected={filters.region}
                property="region"
                menu={regions}
                wrapperRef={regionRef}
                onToggle={() => setOpenRegion(!openRegion)}
                onItemClick={onFilterItemClick}
              />
            </div>
            <div
              className="dashboard__leaderboard__rates__content__filter__right">
              <Dropdown
                open={openSort}
                prefix="Sort"
                position="right"
                selected={filters.sort}
                property="sort"
                menu={sorts}
                wrapperRef={sortRef}
                onToggle={() => setOpenSort(!openSort)}
                onItemClick={onFilterItemClick}
              />
            </div>
          </div>
          <div
            className="dashboard__leaderboard__rates__content__list">
            {currentData.map(item => {
              const name = item.name;
              let sumRate = 0;
              for (var i = 0; i < item.rate.length; i ++) {
                sumRate += parseFloat(item.rate[i].amount);
              };
              const avgRate = sumRate / item.rate.length;
              const markerClass = `dashboard__leaderboard__rates__item__marker dashboard__leaderboard__rates__item__marker${item.root_role === 'Engineer' ? '__engineer' : item.root_role === 'Designer' ? '__designer' : item.root_role === 'Product Manager' ? '__product' : '__other'}`;

              return (
                <div
                  className="dashboard__leaderboard__rates__item"
                  key={item.name}>
                  <div
                    className="dashboard__leaderboard__rates__item__left">
                    <div className={markerClass} />
                    <Label
                      type="primary"
                      size="large"
                      styles="dashboard__leaderboard__rates__item__name">
                      {name}
                    </Label>
                  </div>
                  <div
                    className="dashboard__leaderboard__rates__item__right">
                    <Label type="primary" size="large" font="medium">
                      ${Math.ceil(avgRate)}/hr USD
                    </Label>
                  </div>
                </div>
              )
            })}
          </div>
          <Pagination />
        </div>
      </div>
      <div
        className={`dashboard__leaderboard__rates__intro ${`dashboard__leaderboard__rates__intro__${showIntro ? 'show' : 'hide'}`}`}>
        <div className="dashboard__leaderboard__rates__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__leaderboard__rates__intro__content">
          <div className="dashboard__leaderboard__rates__intro__content__item">
            <Label type="primary" size="large" font="medium">
              Average Skill Market Rates
            </Label>
            <br />
            <Label type="primary" size="medium">
              Skill Rates are calculated from projects that have hired Braintrust talent. Using the tagged skills on the job list, the hired date, and the location of the talent, this metric calculates averages and creates a market rate. As additional jobs are added to the platform, this rate will continue to get more granular.
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
