import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 4" {...props}>
      <defs>
        <style>
          {
            ".cls-green-line-1{fill:none;stroke:#D4E4F9;stroke-miterlimit:10;stroke-width:4px;}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <line className="cls-green-line-1" y1={1} x2={22} y2={1} />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent;
