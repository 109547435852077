import React, { useState } from 'react';
import Button from '../Button';
import Label from '../Label';
import Title from '../Title';
import MultiColorProgressBar from '../MultiColorProgressBar';
import { numberFormatter } from '../../util';
import { CloseBlackIcon, BlackDetailIcon, Pointer } from '../../assets/svgs';

export default function TotalWalletHolders({data}) {
  const [showIntro, setShowIntro] = useState(false);

  const totalToken = 135000000;
  const tokenNoReferrals = +data.token_no_referrals;
  const tokenReferrals = +data.token_referrals;
  const tokenDisbursed = tokenNoReferrals + tokenReferrals;
  const tokenAvailable = totalToken - tokenDisbursed;
  const distribution = {
    availableSupply: (tokenAvailable) * 100 / totalToken,
    disbursed: (tokenDisbursed) * 100 / totalToken,
  }

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  return (
    <div
      className="dashboard__wallet"
      data-aos="fade-up"
      data-aos-delay="50">
      <div className={`dashboard__wallet__main ${`dashboard__wallet__main__${showIntro ? 'hide' : 'show'}`}`}>
        <div className="dashboard__wallet__header">
          <Title type="primary" size="medium" font="medium">
            Community incentives and rewards so far.
          </Title>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__wallet__circulating-supply">
          <Label type="primary" size="large">
            Available supply: {numberFormatter(totalToken)} BTRST
          </Label>
        </div>
        <div className="dashboard__wallet__content">
          <MultiColorProgressBar data={distribution} className="community-disbursements" />
          <div className="community-disbursements__label">
            <Pointer className="community-disbursements__label-pointer" />
            <Title type="primary" size="large" font="medium">
              {numberFormatter(tokenDisbursed)}
            </Title>
            <Label type="primary" size="medium" font="medium">
              BTRST disbursed to the Community
            </Label>
          </div>
        </div>
      </div>
      <div className={`dashboard__wallet__intro ${`dashboard__wallet__intro__${showIntro ? 'show' : 'hide'}`}`}>
        <div className="dashboard__wallet__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashobard__wallet__intro__content">
          <div className="dashboard__wallet__intro__content__item">
            <div className="dashboard__wallet__intro__content__item__left">
              <Label type="primary" size="large" font="medium">
                Community disbursements
              </Label>
            </div>
            <div className="dashboard__wallet__intro__content__item__right">
              <Label type="primary" size="medium">
                The total number of BTRST tokens that have been distributed to community members as a result of community building activities, including referrals.
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
