export const regions = [
  {label: 'World', value: 'world'},
  {label: 'North America', value: 'north-america'},
];

export const sorts = [
  {label: 'A-Z', value: 'a-z'},
  {label: 'Z-A', value: 'z-a'},
  {label: 'High to Low', value: 'high-to-low'},
  {label: 'Low to High', value: 'low-to-high'},
];

export const forEnterprises = [
  {title: 'Why Braintrust', url: `${process.env.REACT_APP_BRAINTRUST_URL}/why-braintrust`},
  {title: 'How it works', url: `${process.env.REACT_APP_BRAINTRUST_URL}/braintrust-for-enterprise`},
  {title: 'Clients', url: `${process.env.REACT_APP_BRAINTRUST_URL}/clients`},
  {title: 'Resources', url: `${process.env.REACT_APP_BRAINTRUST_URL}/resources`},
];

export const forTalent = [
  {title: 'How it works', url: `${process.env.REACT_APP_BRAINTRUST_URL}/how-it-works-for-talent`},
  {title: 'Community', url: `${process.env.REACT_APP_BRAINTRUST_URL}/community`},
  {title: 'Resources', url: `${process.env.REACT_APP_BRAINTRUST_URL}/resources?tab=talent`},
  {title: 'Join Discord', url: "https://discord.gg/rgUS9aHFCB"},
];

export const about = [
  {title: 'About', url: `${process.env.REACT_APP_BRAINTRUST_URL}/about`},
  {title: 'Press', url: `${process.env.REACT_APP_BRAINTRUST_URL}/press`},
  {title: 'Whitepaper', url: `${process.env.REACT_APP_BRAINTRUST_URL}/whitepaper`},
  {title: 'BTRST', url: `${process.env.REACT_APP_BRAINTRUST_URL}/btrst-token`},
  {title: 'FAQ', url: `${process.env.REACT_APP_BRAINTRUST_URL}/frequently-asked-questions`},
];

export const countries = [
  {country: 'Andorra', region: 'EMEA'},
  {country: 'United Arab Emirates', region:	'EMEA'},
  {country: 'Afghanistan', region:	'EMEA'},
  {country: 'Antigua and Barbuda', region:	'LATAM'},
  {country: 'Anguilla', region:	'LATAM'},
  {country: 'Albania', region:	'EMEA'},
  {country: 'Armenia', region:	'EMEA'},
  {country: 'Angola', region:	'EMEA'},
  {country: 'Antarctica', region:	'EMEA'},
  {country: 'Argentina', region:	'LATAM'},
  {country: 'American Samoa', region:	'APAC'},
  {country: 'Austria', region:	'EMEA'},
  {country: 'Australia', region:	'APAC'},
  {country: 'Aruba', region:	'LATAM'},
  {country: 'Åland Islands', region:	'EMEA'},
  {country: 'Azerbaijan', region:	'EMEA'},
  {country: 'Bosnia and Herzegovina', region:	'EMEA'},
  {country: 'Barbados', region:	'LATAM'},
  {country: 'Bangladesh', region:	'APAC'},
  {country: 'Belgium', region:	'EMEA'},
  {country: 'Burkina Faso', region:	'EMEA'},
  {country: 'Bulgaria', region:	'EMEA'},
  {country: 'Bahrain', region:	'EMEA'},
  {country: 'Burundi', region:	'EMEA'},
  {country: 'Benin', region:	'EMEA'},
  {country: 'Saint Barthélemy', region:	'EMEA'},
  {country: 'Bermuda', region:	'LATAM'},
  {country: 'Brunei Darussalam', region:	'APAC'},
  {country: 'Bolivia', region:	'LATAM'},
  {country: 'Brazil', region:	'LATAM'},
  {country: 'Bahamas', region:	'LATAM'},
  {country: 'Bhutan', region:	'APAC'},
  {country: 'Bouvet Island', region:	'EMEA'},
  {country: 'Botswana', region:	'EMEA'},
  {country: 'Belarus', region:	'EMEA'},
  {country: 'Belize', region:	'LATAM'},
  {country: 'Canada', region:	'NA'},
  {country: 'Cocos', region:	'APAC'},
  {country: 'Congo', region:	'EMEA'},
  {country: 'Central African Republic', region:	'EMEA'},
  {country: 'Switzerland', region:	'EMEA'},
  {country: 'Côte d\'Ivoire', region:	'EMEA'},
  {country: 'Cook Islands', region:	'EMEA'},
  {country: 'Chile', region:	'LATAM'},
  {country: 'Cameroon', region:	'EMEA'},
  {country: 'China', region:	'APAC'},
  {country: 'Colombia', region:	'LATAM'},
  {country: 'Costa Rica', region:	'LATAM'},
  {country: 'Cuba', region:	'LATAM'},
  {country: 'Cabo Verde', region:	'EMEA'},
  {country: 'Curaçao', region:	'EMEA'},
  {country: 'Christmas Island', region:	'APAC'},
  {country: 'Cyprus', region:	'EMEA'},
  {country: 'Czechia', region:	'EMEA'},
  {country: 'Germany', region:	'EMEA'},
  {country: 'Djibouti', region:	'EMEA'},
  {country: 'Denmark', region:	'EMEA'},
  {country: 'Dominica', region:	'LATAM'},
  {country: 'Dominican Republic', region:	'LATAM'},
  {country: 'Algeria', region:	'LATAM'},
  {country: 'Ecuador', region:	'LATAM'},
  {country: 'Estonia', region:	'EMEA'},
  {country: 'Egypt', region:	'EMEA'},
  {country: 'Western Sahara', region:	'EMEA'},
  {country: 'Eritrea', region:	'EMEA'},
  {country: 'Spain', region:	'EMEA'},
  {country: 'Ethiopia', region:	'EMEA'},
  {country: 'Finland', region:	'EMEA'},
  {country: 'Fiji', region:	'EMEA'},
  {country: 'Malvinas', region:	'LATAM'},
  {country: 'Micronesia', region:	'APAC'},
  {country: 'Faroe Islands', region:	'EMEA'},
  {country: 'France', region:	'EMEA'},
  {country: 'Gabon', region:	'EMEA'},
  {country: 'UK', region:	'EMEA'},
  {country: 'Grenada', region:	'LATAM'},
  {country: 'Georgia', region:	'EMEA'},
  {country: 'French Guiana', region:	'EMEA'},
  {country: 'Guernsey', region:	'EMEA'},
  {country: 'Ghana', region:	'EMEA'},
  {country: 'Gibralta', region:	'EMEA'},
  {country: 'Greenland', region:	'EMEA'},
  {country: 'Gambia', region:	'EMEA'},
  {country: 'Guinea', region:	'EMEA'},
  {country: 'Guadeloupe', region:	'EMEA'},
  {country: 'Equatorial Guinea', region:	'EMEA'},
  {country: 'Greece', region:	'EMEA'},
  {country: 'South Georgia and the South Sandwich Islands', region:	'LATAM'},
  {country: 'Guatemala', region:	'LATAM'},
  {country: 'Guam', region:	'APAC'},
  {country: 'Guinea-Bissau', region:	'EMEA'},
  {country: 'Guyana', region:	'LATAM'},
  {country: 'Hong Kong', region:	'APAC'},
  {country: 'Heard Island and McDonald Islands', region:	'EMEA'},
  {country: 'Honduras', region:	'LATAM'},
  {country: 'Croatia', region:	'EMEA'},
  {country: 'Haiti', region:	'LATAM'},
  {country: 'Hungary', region:	'EMEA'},
  {country: 'Indonesia', region:	'APAC'},
  {country: 'Ireland', region:	'EMEA'},
  {country: 'Israel', region:	'EMEA'},
  {country: 'Isle of Man', region:	'EMEA'},
  {country: 'India', region:	'APAC'},
  {country: 'British Indian Ocean Territory', region:	'APAC'},
  {country: 'Iraq', region:	'EMEA'},
  {country: 'Iran', region:	'EMEA'},
  {country: 'Iceland', region:	'EMEA'},
  {country: 'Italy', region:	'EMEA'},
  {country: 'Jersey', region:	'EMEA'},
  {country: 'Jamaica', region:	'LATAM'},
  {country: 'Jordan', region:	'EMEA'},
  {country: 'Japan', region:	'APAC'},
  {country: 'Kenya', region:	'EMEA'},
  {country: 'Kyrgyzstan', region:	'EMEA'},
  {country: 'Cambodia', region:	'APAC'},
  {country: 'Kiribati', region:	'APAC'},
  {country: 'Comoros', region:	'EMEA'},
  {country: 'Saint Kitts and Nevis', region:	'APAC'},
  {country: 'Korea', region:	'LATAM'},
  {country: 'Kuwait', region:	'EMEA'},
  {country: 'Cayman Islands', region:	'LATAM'},
  {country: 'Kazakhstan', region:	'EMEA'},
  {country: 'Lao People\s Democratic Republic', region:	'APAC'},
  {country: 'Lebanon', region:	'EMEA'},
  {country: 'Saint Lucia', region:	'LATAM'},
  {country: 'Liechtenstein', region:	'EMEA'},
  {country: 'Sri Lanka', region:	'APAC'},
  {country: 'Liberia', region:	'EMEA'},
  {country: 'Lesotho', region:	'EMEA'},
  {country: 'Lithuania', region:	'EMEA'},
  {country: 'Luxembourg', region:	'EMEA'},
  {country: 'Latvia', region:	'EMEA'},
  {country: 'Libya', region:	'EMEA'},
  {country: 'Morocco', region:	'EMEA'},
  {country: 'Monaco', region:	'EMEA'},
  {country: 'Moldova', region:	'EMEA'},
  {country: 'Montenegro', region:	'EMEA'},
  {country: 'Collectivity of Saint Martin', region:	'EMEA'},
  {country: 'Madagascar', region:	'EMEA'},
  {country: 'Marshall Islands', region:	'APAC'},
  {country: 'Macedonia', region:	'EMEA'},
  {country: 'Mali', region:	'EMEA'},
  {country: 'Myanmar', region:	'APAC'},
  {country: 'Mongolia', region:	'APAC'},
  {country: 'Macao', region:	'APAC'},
  {country: 'Northern Mariana Islands', region:	'APAC'},
  {country: 'Martinique', region:	'EMEA'},
  {country: 'Mauritania', region:	'EMEA'},
  {country: 'Montserrat', region:	'LATAM'},
  {country: 'Malta', region:	'EMEA'},
  {country: 'Mauritius', region:	'EMEA'},
  {country: 'Maldives', region:	'APAC'},
  {country: 'Malawi', region:	'EMEA'},
  {country: 'Mexico', region:	'LATAM'},
  {country: 'Malaysia', region:	'APAC'},
  {country: 'Mozambique', region:	'EMEA'},
  {country: 'Namibia', region:	'EMEA'},
  {country: 'New Caledonia', region:	'EMEA'},
  {country: 'Niger', region:	'EMEA'},
  {country: 'Norfolk Island', region:	'APAC'},
  {country: 'Nigeria', region:	'EMEA'},
  {country: 'Nicaragua', region:	'LATAM'},
  {country: 'Netherlands', region:	'EMEA'},
  {country: 'Norway', region:	'EMEA'},
  {country: 'Nepal', region:	'APAC'},
  {country: 'Nauru', region:	'APAC'},
  {country: 'Niue', region:	'APAC'},
  {country: 'New Zealand', region:	'APAC'},
  {country: 'Oman', region:	'EMEA'},
  {country: 'Panama', region:	'LATAM'},
  {country: 'Peru', region:	'LATAM'},
  {country: 'French Polynesia', region:	'EMEA'},
  {country: 'Papua New Guinea', region:	'APAC'},
  {country: 'Philippines', region:	'APAC'},
  {country: 'Pakistan', region:	'APAC'},
  {country: 'Poland', region:	'EMEA'},
  {country: 'Saint Pierre and Miquelon', region:	'EMEA'},
  {country: 'Pitcairn', region:	'APAC'},
  {country: 'Puerto Rico', region:	'LATAM'},
  {country: 'Palestine', region:	'EMEA'},
  {country: 'Portugal', region:	'EMEA'},
  {country: 'Palau', region:	'APAC'},
  {country: 'Paraguay', region:	'LATAM'},
  {country: 'Qatar', region:	'EMEA'},
  {country: 'Réunion', region:	'EMEA'},
  {country: 'Romania', region:	'EMEA'},
  {country: 'Serbia', region:	'EMEA'},
  {country: 'Russia', region:	'EMEA'},
  {country: 'Rwanda', region:	'EMEA'},
  {country: 'Saudi Arabia', region:	'APAC'},
  {country: 'Solomon Islands', region:	'APAC'},
  {country: 'Seychelles', region:	'EMEA'},
  {country: 'Sudan', region:	'EMEA'},
  {country: 'Sweden', region:	'EMEA'},
  {country: 'Singapore', region:	'APAC'},
  {country: 'Saint Helena', region:	'EMEA'},
  {country: 'Slovenia', region:	'EMEA'},
  {country: 'Slovakia', region:	'EMEA'},
  {country: 'Sierra Leone', region:	'EMEA'},
  {country: 'San Marino', region:	'EMEA'},
  {country: 'Senegal', region:	'EMEA'},
  {country: 'Somalia', region:	'EMEA'},
  {country: 'Suriname', region:	'LATAM'},
  {country: 'South Sudan', region:	'EMEA'},
  {country: 'Sao Tome and Principe', region:	'EMEA'},
  {country: 'El Salvador', region:	'LATAM'},
  {country: 'Sint Maarten', region:	'EMEA'},
  {country: 'Syrian Arab Republic', region:	'EMEA'},
  {country: 'Swaziland', region:	'EMEA'},
  {country: 'Turks and Caicos Islands', region:	'LATAM'},
  {country: 'Chad', region:	'EMEA'},
  {country: 'French Southern Territories', region:	'EMEA'},
  {country: 'Togo', region:	'EMEA'},
  {country: 'Thailand', region:	'APAC'},
  {country: 'Tajikistan', region:	'EMEA'},
  {country: 'Tokelau', region:	'APAC'},
  {country: 'Timor-Leste', region:	'APAC'},
  {country: 'Turkmenistan', region:	'EMEA'},
  {country: 'Tunisia', region:	'EMEA'},
  {country: 'Tonga', region:	'APAC'},
  {country: 'Turkey', region:	'EMEA'},
  {country: 'Trinidad and Tobago', region:	'LATAM'},
  {country: 'Tuvalu', region:	'APAC'},
  {country: 'Taiwan', region:	'APAC'},
  {country: 'Tanzania', region:	'EMEA'},
  {country: 'Ukraine', region:	'EMEA'},
  {country: 'Uganda', region:	'EMEA'},
  {country: 'US', region:	'NA'},
  {country: 'Uruguay', region:	'LATAM'},
  {country: 'Uzbekistan', region:	'EMEA'},
  {country: 'Holy See', region:	'EMEA'},
  {country: 'Saint Vincent and the Grenadines', region:	'LATAM'},
  {country: 'Venezuela', region:	'LATAM'},
  {country: 'Vietnam', region:	'APAC'},
  {country: 'Wallis and Futuna', region:	'EMEA'},
  {country: 'Samoa', region:	'APAC'},
  {country: 'Mayotte', region:	'EMEA'},
  {country: 'South Africa', region:	'EMEA'},
  {country: 'Zambia', region:	'EMEA'},
  {country: 'Yemen', region:	'EMEA'},
  {country: 'Zimbabwe', region:	'EMEA'},
  {country: 'Asia-Pacific', region: 'APAC'},
];
