import React from 'react';
import TabItem from './TabItem';

export default function Tab({list, selected, onTabItemClick}) {
  return (
    <div className="tab-container">
      {list.map(item => (
        <TabItem
          key={item}
          selected={selected}
          item={item}
          onClick={onTabItemClick}
        />
      ))}
    </div>
  )
}
