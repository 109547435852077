
import React from 'react';
import Label from '../Label';
import Title from '../Title';
import Display from '../Display';
import { 
    LogoIcon, 
    FeeIcon, 
    FeeArrowIconRight,
    FeeArrowIconDown,
} from '../../assets/svgs';
import { numberWithCommas } from '../../util';

export default function FeeConverter({ totalBTRST, totalUSD }) {
    if (!totalUSD || !totalBTRST) {
        return null;
      }
     
      const formattedTotalBTRST = numberWithCommas(Math.round((parseFloat(totalBTRST) + Number.EPSILON) * 100) / 100);
      const formattedTotalUSD = numberWithCommas(Math.round((parseFloat(totalUSD) + Number.EPSILON) * 100) / 100);

    return (
      <div 
        className="dashboard__converter"
        data-aos="fade-up"
        data-aos-delay="50">
            <div className="dashboard__converter__overlay" />
            <div className="dashboard__converter__main" >
                <Title 
                    type="white" 
                    size="medium" 
                    font="medium">
                    The BTRST Fee Converter funds network growth.
                </Title>
                <div className="dashboard__converter__content" >
                    <div className='dashboard__converter__client-fees'>
                        <div className='dashboard__converter__icon'>
                        <FeeIcon width={44} height={44}/>
                        </div>
                        <Display type="white" size="small" text={`$${formattedTotalUSD} USD`} />
                        <Label
                            type="white" 
                            size="large">
                            <Link url="https://fees.btrstinfo.xyz">
                                in client fees
                            </Link>
                        </Label>
                    </div>
                    <div className='dashboard__converter__arrow-right'>
                        <FeeArrowIconRight width={120} height={59} />
                    </div>
                    <div className='dashboard__converter__arrow-down'>
                        <FeeArrowIconDown width={53} height={36} />
                    </div>
                    <div className='dashboard__converter__client-fees'>
                        <div className='dashboard__converter__icon'>
                        <LogoIcon width={44} height={44}/>
                        </div>
                        <Display type="white" size="small" text={`${formattedTotalBTRST} BTRST`} />
                        <Label type="white" size="large">
                            for the community
                        </Label>
                    </div>
                </div>
                <div>
                   <div className='dashboard__converter__horizontal-line'/>
                    <Title 
                        type="white" 
                        size="small" 
                        font="medium"
                        styles="dashboard__converter__mb10">
                        How it works
                    </Title>
                </div>
                <HowItWorksSection />
            </div>
    </div>
    )
  }

  const HowItWorksSection = () => {
    return <div className='dashboard__converter__bottom-section'>
        <div className='dashboard__converter__bottom-section__item'>
            <CircleNumber children="1"/>
            <Label type="white" size="medium">
                Clients’ 10% invoice fee is sent to the <Link url="https://github.com/Snowfork/braintrust-fee-converter">BTRST Fee Converter.</Link>
            </Label>
        </div>
        <div className='dashboard__converter__bottom-section__item'>
            <CircleNumber children="2"/>
            <Label type="white" size="medium">
                The Converter <Link url="https://etherscan.io/address/0x438e6416fe63863c434e4d6ee0c39d8f96880186">purchases</Link> BTRST on the open market.
            </Label>
        </div>
        <div className='dashboard__converter__bottom-section__item'>
            <CircleNumber children="3"/>
            <Label type="white" size="medium">
                BTRST is sent to the <Link 
                url="https://etherscan.io/address/0xb6f1f016175588a049fda12491cf3686de33990b#tokentxns">Braintrust DAO.</Link>
            </Label>
        </div>
        <div className='dashboard__converter__bottom-section__item'>
            <CircleNumber children="4"/>
            <Label type="white" size="medium">
            The DAO funds <Link url="https://www.usebraintrust.com/grants">community programs</Link> to grow the network.   
            </Label>
        </div>
    </div>
  }

  const CircleNumber = ({ children }) => {
    return <div className='dashboard__converter__bottom-section__circle'>
         {children}
      </div>
  }

  const Link = ({ url, children }) => {
    return (
        <a
          href={url}
          className="dashboard__converter__link"
          target="_blank"
          rel="noreferrer">
          {children}
        </a>
      )
  }