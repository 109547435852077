import React from 'react';
import Label from '../Label';

export default function TabItem({item, selected, onClick}) {
  const active = item === selected;
  return (
    <div
      className={`tab-item ${active ? 'tab-item__active' : ''}`}
      onClick={() => onClick(item)}>
      <Label
        type="primary"
        size="medium"
        font={active ? "medium" : "regular"}>
        {item}
      </Label>
    </div>
  )
}
