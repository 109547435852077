import React, {useState} from 'react';

import Button from '../Button';
import Label from '../Label';
import Title from '../Title';
import { numberWithCommas } from '../../util';
import {
  ChevronLeftIcon,
  ChevronLeftActiveIcon,
  ChevronRightIcon,
  ChevronRightActiveIcon,
  LogoIcon,
  CloseBlackIcon,
  BlackDetailIcon,
} from '../../assets/svgs';

export default function BTRSTLeaderBoard({data}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [showIntro, setShowIntro] = useState(false);

  let result = data ? data.sort((a, b) => b.tokens - a.tokens) : [];
  result = result.length > 24 ? result.slice(0, 24) : result;

  const pageSize = 12;
  const totalPage = Math.ceil(result.length / pageSize);
  const currentData = result.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  const handlePrev = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPage) setCurrentPage(currentPage + 1);
  };

  const Pagination = () => (
    <div className="dashboard__leaderboard__pagination">
      <Button
        styles="dashboard__leaderboard__pagination__btn-prev"
        disabled={currentPage === 1}
        onClick={handlePrev}>
        {currentPage === 1 ? (
          <ChevronLeftIcon />
        ) : <ChevronLeftActiveIcon />}
      </Button>
      <Label type="primary" size="small">
        Page {currentPage} of {totalPage}
      </Label>
      <Button
        styles="dashboard__leaderboard__pagination__btn-next"
        disabled={currentPage === totalPage}
        onClick={handleNext}>
        {currentPage === totalPage ? (
          <ChevronRightIcon />
        ) : (
          <ChevronRightActiveIcon />
        )}
      </Button>
    </div>
  );

  return (
    <div
      className="dashboard__leaderboard__btrst"
      data-aos="fade-up"
      data-aos-delay="50">
      <div
        className={`dashboard__leaderboard__btrst__main ${`dashboard__leaderboard__btrst__main__${showIntro ? 'hide' : 'show'}`}`}>
        <div className="dashboard__leaderboard__rates__header">
          <Title type="primary" size="medium" font="medium">
            Referral leaderboard.
          </Title>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__leaderboard__btrst__content">
          {currentData.map(item => (
            <div
              className="dashboard__leaderboard__btrst__item"
              key={item.id}>
              <div className="dashboard__leaderboard__btrst__item__left">
                <img src={item.avatar} alt="" width={30} height={30} />
                <Label type="primary" size="large">
                  &nbsp;&nbsp;{item.first_name}&nbsp;{item.last_initial}.
                </Label>
              </div>
              <div className="dashboard__leaderboard__btrst__item__right">
                <LogoIcon width={16.79} height={ 16.15} />
                <Label type="primary" size="large">
                  &nbsp;{numberWithCommas(Math.floor(item.tokens))}
                </Label>
              </div>
            </div>
          ))}
        </div>
        <Pagination />
      </div>
      <div
        className={`dashboard__leaderboard__btrst__intro ${`dashboard__leaderboard__btrst__intro__${showIntro ? 'show' : 'hide'}`}`}>
        <div className="dashboard__leaderboard__btrst__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__leaderboard__btrst__intro__content">
          <div className="dashboard__leaderboard__btrst__intro__content__item">
            <Label type="primary" size="large" font="medium">
              Referrers:
            </Label>
            <br />
            <Label type="primary" size="medium">
              The leaderboard recognizes Braintrust users that have earned BTRST by referring clients and talents to the Braintrust network.
              Users that refer clients receive 2% of their total billings in BTRST - up to 10k USD.
              Users that refer talent receive 1% of their total earnings in BTRST - up to 10k USD.
            </Label>
          </div>
          <div className="dashboard__leaderboard__btrst__intro__content__item">
            <Label type="primary" size="large" font="medium">
              How do I start referring clients and talents to Braintrust?
            </Label>
            <br />
            <Label type="primary" size="medium">
              Create a Braintrust account and share your unique referral code (available after sign-up) with clients and talent that are a great fit for Braintrust.
              You can find more information on the EARN tab in the Braintrust platform.
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
