import React, { useState } from 'react';

import Button from '../Button';
import Display from '../Display';
import Label from '../Label';
import Title from '../Title';

import { numberWithCommas } from '../../util';
import { CloseWhiteIcon, DetailIcon } from '../../assets/svgs';

export default function ProjectMetrics({avg_project_size, avg_project_duration}) {
  const [showIntro, setShowIntro] = useState(false);

  const onToggle = () => {
    setShowIntro(!showIntro);
  };
 
  return (
    <div
      className="dashboard__metrics__left"
      data-aos="fade-up"
      data-aos-delay="50">
      <div className="dashboard__metrics__left__overlay" />
      <div
        className={`dashboard__metrics__left__main ${`dashboard__metrics__left__main__${showIntro ? 'hide' : 'show'}`}`}>
        <div className="dashboard__metrics__left__header">
          <Title type="white" size="medium" font="medium">
            Braintrust project metrics.
          </Title>
          <Button onClick={onToggle}>
            <DetailIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__metrics__left__content">
          <div>
            <Label type="white" size="large">Average Project Size</Label>
            <Display type="white" size="medium-small" text={`$${numberWithCommas(Math.floor(avg_project_size))}`} />
          </div>
          <div className="dashboard__metrics__left__content__apd">
            <Label type="white" size="large">Average Project Duration</Label>
            <Display type="white" size="medium-small" text={`${avg_project_duration ?? 0} days`} />
          </div>
        </div>
      </div>
      <div
        className={`dashboard__metrics__left__intro ${`dashboard__metrics__left__intro__${showIntro ? 'show' : 'hide'}`}`}>
        <div className="dashboard__metrics__left__header">
          <Title type="white" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseWhiteIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__metrics__left__intro__content">
          <div className="dashboard__metrics__left__intro__content__aps">
            <Label size="large" type="white" font="medium">
              Average Project Size
            </Label>
            <br/>
            <Label size="medium" type="white">
              This metric uses previously submitted invoices. Average Project size is calculated by the number of weeks of a project x hrs/week x hourly rate.
            </Label>
          </div>
          <div className="dashboard__metrics__left__intro__content__apd">
            <Label size="large" type="white" font="medium">
              Average Project Duration
            </Label>
            <br/>
            <Label size="medium" type="white">
              This metric is pulled based on the number of active and completed projects. The start date is set when an offer is made to talent, and the end date is calculated based on when the project is marked as complete, or the anticipated end date. We average the total project duration, and round to the nearest half month.
            </Label>
          </div>
        </div>
        <div />
      </div>
    </div>
  )
}
