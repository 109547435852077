import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 41 57" 
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
        <defs>
        <style>{".cls-logo-icon-1{fill:currentColor;}"}</style>
      </defs>
      <path d="M20 0V52.5" stroke="white" stroke-width="5"/>
      <path d="M2 36L20 53L38.5 36" stroke="white" stroke-width="5"/>
      </svg>
  )
}

export default SvgComponent;
