import React from 'react';

export default function Label({font, size, styles, type, textAlign, children, className}) {
  return (
    <div
      className={`${styles} label label__${size} label__${type} label__${textAlign} label__font__${font} ${className}`}>
      {children}
    </div>
  )
}
