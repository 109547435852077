import React, { useState } from 'react';

import Button from '../Button';
import Label from '../Label';
import Title from '../Title';
import Display from '../Display';
import { CloseWhiteIcon, DetailIcon } from '../../assets/svgs';

export default function GSV({data}) {
  const [showIntro, setShowIntro] = useState(false);

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  return (
    <div
      className="dashboard__gsv"
      data-aos="fade-up"
      data-aos-delay="50">
      <div className="dashboard__gsv__overlay" />
      <div
        className={`dashboard__gsv__main ${`dashboard__gsv__main__${showIntro ? 'hide' : 'show'}`}`}>
        <div className="dashboard__gsv__header">
          <Title type="white" size="medium" font="medium">
            Total dollars earned by the Braintrust community.
          </Title>
          <Button onClick={onToggle}><DetailIcon className="icon__detail" /></Button>
        </div>
        <div className="dashboard__gsv__content">
          {data && (
            <Display type="white" size="medium" text={`$${data}`} />
          )}
          <Label type="white" size="large">
            Represented by Braintrust's GSV <span className="dashboard__gsv__content__mobile-br">(Gross Service Value)</span>
          </Label>
        </div>
      </div>
      <div className={`dashboard__gsv__intro ${`dashboard__gsv__intro__${showIntro ? 'show' : 'hide'}`}`}>
        <div className="dashboard__gsv__header">
          <Title type="white" size="medium" font="medium">Definitions</Title>
          <Button onClick={onToggle}><CloseWhiteIcon className="icon__detail" /></Button>
        </div>
        <div className="dashboard__gsv__intro__content">
          <div className="dashboard__gsv__intro__content__item">
            <div className="dashboard__gsv__intro__content__item__left">
              <Label type="white" size="large" font="medium">Gross Service Value</Label>
            </div>
            <div className="dashboard__gsv__intro__content__item__right">
              <Label type="white" size="medium">
                GSV represents the actual dollar amount that has come into Braintrust before being distributed to the freelance talent. This is how Braintrust measures the network size and growth. For example, if a client hires three developers who are billing at 10K/month. The GSV for the month would be $33k ($10k x 3 dev + 10% BT fee).
              </Label>
            </div>
          </div>
          <div className="dashboard__gsv__intro__content__item">
            <div className="dashboard__gsv__intro__content__item__left">
              <Label type="white" size="large" font="medium">Gross Contract Value</Label>
            </div>
            <div className="dashboard__gsv__intro__content__item__right">
              <Label type="white" size="medium">
                GCV looks at all the projects that have accepted offers and the anticipated contract value. This metric represents all actual and anticipated income into the Braintrust network. For example, if a client hires three developers who are billing at 10k/month, for a 6 month project, the GCV would be $198k ($10k x 3 dev x 6 months + 10% BT fee).
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
