import React, { useState } from 'react';
import Button from '../Button';
import Label from '../Label';
import Link from '../Link';

import { forEnterprises, forTalent, about } from '../../constants';
import { ArrowDownIcon, ArrowRightIcon, Logo, CloseBlackIcon, BlackLine } from '../../assets/svgs';

export default function NavigationMobile({scrolling}) {
  const [expanded, setExpanded] = useState(false);
  const [submenuExpanded, setSubmenuExpanded] = useState({
    forTalent: false,
    forEnterprises: false,
    about: false,
  });

  const onToggleMenu = () => {
    setExpanded(!expanded);
  };

  const onToggleSubMenu = (property) => {
    setSubmenuExpanded({
      ...submenuExpanded,
      [property]: !submenuExpanded[property],
    });
  };

  const gotoTalent = () => {
    window.location.href = `${process.env.REACT_APP_BRAINTRUST_URL}/why-braintrust`;
  };

  return (
    <div className={`navigation-mobile ${scrolling ? 'navigation-mobile__scrolled' : ''}`}>
      <div className="navigation-mobile__header">
        <Link
          styles="navigation__logo"
          url={`${process.env.REACT_APP_BRAINTRUST_URL}/?hsLang=en`}>
          <Logo className="navigation__logo__img" />
        </Link>
        <Button onClick={onToggleMenu}>
          {expanded ? (
            <CloseBlackIcon width={20} height={20} />
          ) : (
            <div
              className="navigation-mobile__header__icon">
              <BlackLine
                className="navigation-mobile__header__icon__1"
                width={20.69}
                height={1.25}
              />
              <BlackLine
                className="navigation-mobile__header__icon__2"
                width={20.69}
                height={1.25}
              />
            </div>
          )}
        </Button>
      </div>
      {expanded && (
        <div className="navigation-mobile__content">
          <div className="navigation-mobile__content__item">
            <div className="navigation-mobile__content__item__content">
              <Label type="primary" size="large" font="medium">For Talent</Label>
              <Button onClick={() => onToggleSubMenu('forTalent')}>
                <ArrowDownIcon width={15} height={10} />
              </Button>
            </div>
            {submenuExpanded['forTalent'] && (
              <div className="navigation-mobile__content__item__submenu">
                {forTalent.map(item => (
                  <div className="navigation-mobile__content__item__submenu__item">
                    <BlackLine width={20.69} height={1.25} />
                    <Link
                      url={`${item.url}`}
                      key={item.title}>
                      <Label type="primary" size="large">{item.title}</Label>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="navigation-mobile__content__item">
            <div className="navigation-mobile__content__item__content">
              <Label type="primary" size="large" font="medium">For Enterprises</Label>
              <Button onClick={() => onToggleSubMenu('forEnterprises')}>
                <ArrowDownIcon width={15} height={10} />
              </Button>
            </div>
            {submenuExpanded['forEnterprises'] && (
              <div className="navigation-mobile__content__item__submenu">
                {forEnterprises.map(item => (
                  <div className="navigation-mobile__content__item__submenu__item">
                    <BlackLine width={20.69} height={1.25} />
                    <Link
                      url={`${item.url}`}
                      key={item.title}>
                      <Label type="primary" size="large">{item.title}</Label>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="navigation-mobile__content__item">
            <div className="navigation-mobile__content__item__content">
              <Label type="primary" size="large" font="medium">About</Label>
              <Button onClick={() => onToggleSubMenu('about')}>
                <ArrowDownIcon width={15} height={10} />
              </Button>
            </div>
            {submenuExpanded['about'] && (
              <div className="navigation-mobile__content__item__submenu">
                {about.map(item => (
                  <div className="navigation-mobile__content__item__submenu__item">
                    <BlackLine width={20.69} height={1.25} />
                    <Link
                      url={`${item.url}`}
                      key={item.title}>
                      <Label type="primary" size="large">{item.title}</Label>
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="navigation-mobile__content__item">
            <Link
              url={`${process.env.REACT_APP_BRAINTRUST_URL}/governance`}
              styles="navigation-mobile__content__item__content">
              <Label type="primary" size="large" font="medium">Governance</Label>
            </Link>
          </div>
          <div className="navigation-mobile__content__item">
            <Link
              url={`${process.env.REACT_APP_BRAINTRUST_URL}/blog`}
              styles="navigation-mobile__content__item__content">
              <Label type="primary" size="large" font="medium">Blog</Label>
            </Link>
          </div>
          <div className="navigation-mobile__content__item">
            <Link
              url={`${process.env.REACT_APP_BRAINTRUST_APP_URL}`}
              styles="navigation-mobile__content__item__content">
              <Label type="primary" size="large" font="medium">Login</Label>
            </Link>
          </div>
          <div className="navigation-mobile__blackline" />
          <div className="navigation-mobile__content__item">
            <Button
              styles="navigation-mobile__content__item__btn"
              onClick={gotoTalent}>
              <Label type="prmary" size="large" font="medium">Hire Talent</Label>
              <ArrowRightIcon width={25} height={25} />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
};
