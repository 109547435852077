import React, { useState, useEffect } from 'react';
import {
  Navigation,
  NavigationMobile,
} from '../../components/Navigation';
import Footer from '../../components/Footer';
import {
  AverageMarketRates,
  BTRSTLeaderBoard,
  Feedback,
  GSV,
  Header,
  Metrics,
  NetworkGrowth,
  TokenAllocation,
  TotalWalletHolders,
  UpdateInfo,
  FeeConverter,
} from '../../components/Dashboard';
import { getDashboardData } from '../../actions/dashboard';
import { numberWithCommas } from '../../util';

export default function Dashboard() {
  const [skillRates, setSkillRates] = useState([]);
  const [data, setData] = useState();
  const [scrolling, setScrolling] = useState(false);

  const calculateSkillRates = (data) => {
    let result = [];
    data.forEach(item => {
      const existSkill = result.find(skill => skill.name === item.skill);
      if (existSkill) {
        const updatedSkill = {
          name: existSkill.name,
          rate: [...existSkill.rate, {amount: item.rate, date: item.date}],
          root_role: item.root_role,
          location: item.location
        };
        result = result.map(skill => skill.name === existSkill.name ? updatedSkill : skill);
      } else {
        result = [
          ...result,
          {
            name: item.skill,
            rate: [{amount: item.rate, date: item.date}],
            root_role: item.root_role,
            location: item.location,
          },
        ]
      }
    });
    setSkillRates(result);
  };

  const trackingScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', trackingScroll);
    getDashboardData().then(result => {
      const {data, status_code} = result;
      if (status_code === 200) {
        setData(data);
        calculateSkillRates(data.skill_rates);
      }
    });
  }, []);

  const gsvData = data?.dashboard ? numberWithCommas(Math.floor(data.dashboard.lifetime_network_gsv)) : null;
  const token_referrals = data?.dashboard ? data.dashboard.total_tokens_earned_by_referrals : null;
  const token_no_referrals = data?.dashboard ? data.dashboard.total_tokens_earned_by_non_referrals : null;

  return (
    <div className="dashboard__container">
      <Navigation scrolling={scrolling} />
      <NavigationMobile scrolling={scrolling} />
      <div className="dashboard__main">
        <Header />
        <UpdateInfo date={data?.dashboard.created_at} />
        <GSV data={gsvData} />
        <NetworkGrowth data={data ? {lifeGSV: data.gsv_data, lifeGCV: data.gcv_data} : null} />
        <FeeConverter 
          totalBTRST={data?.dashboard.total_tokens_purchased}
          totalUSD={data?.dashboard.total_bt_fee} />
        <div className="dashboard__token-distribution">
          <TokenAllocation circulatingSupply={data?.dashboard.token_circulating_supply}  />
          <TotalWalletHolders data={{
            token_referrals,
            token_no_referrals,
            circulating_supply: data?.dashboard.token_circulating_supply
          }} />
        </div>
        <Metrics data={data ? data.dashboard : null}/>
        <div className="dashboard__leaderboard">
          <BTRSTLeaderBoard data={data ? data.btrust_earners : null} />
          <AverageMarketRates data={skillRates} />
        </div>
        <Feedback />
      </div>
      <Footer />
    </div>
  )
}
