import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width="84" height="41" viewBox="0 0 84 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M84 1H35L1 40" stroke="black"/>
    </svg>
  )
}

export default SvgComponent;
