import React, { useEffect, useRef } from 'react';
import Label from '../Label';
import { ArrowDownIcon, VerticalLine } from '../../assets/svgs';

export default function Dropdown({
  selected,
  styles,
  menu,
  open,
  prefix,
  position,
  property,
  wrapperRef,
  onToggle,
  onItemClick,
}) {
  const selectedItem = menu.find(item => item.value === selected);
  
  return (
    <div className={`drop-down ${styles ? styles : ''}`}>
      <div className="drop-down__toggle" onClick={onToggle}>
        <Label
          styles="drop-down__toggle__label"
          type="primary"
          size="small">
          {prefix ? <>{prefix}&nbsp;</> : ''}{selectedItem.label}
        </Label>
        <ArrowDownIcon width={9.24} height={5.68} />
      </div>
      {open && (
        <div
          ref={wrapperRef} 
          className={`drop-down__list ${position ? `drop-down__list__${position}` : ''} ${`drop-down__list__${open ? 'show' : 'hide'}`}`}>
          {menu.map(item => (
            <div
              className="drop-down__item"
              key={item.value}
              onClick={() => {
                onItemClick(property, item.value);
                onToggle();
              }}>
              {item.value === selected && (
                <div className="drop-down__item__left">
                  <VerticalLine width={4.8} height={32.01} />
                </div>
              )}
              <Label size="medium" type="primary">{item.label}</Label>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
