import React from 'react';

import Label from '../Label';
import Link from '../Link';
import Button from '../Button';
import { forEnterprises, forTalent, about } from '../../constants';
import {Logo, ArrowDownIcon} from '../../assets/svgs';

export default function Navigation({scrolling}) {
  const SubMenu = ({data, property}) => {
    return (
      <div
        id={`submenu-${property}`}
        className="navigation__item__submenu">
        {data.map(item => (
          <Link
            url={`${item.url}`}
            size="small"
            styles="navigation__item__submenu__item"
            key={item.title}>
            {item.title}
          </Link>
        ))}
      </div>
    )
  };

  const gotoTalent = () => {
    window.open(
      `${process.env.REACT_APP_BRAINTRUST_URL}/why-braintrust`,
      '_blank',
    );
  };

  return (
    <div className={`nav ${scrolling ? 'nav__scrolled' : ''}`}>
      <div className="navigation">
        <Link
          styles="navigation__logo"
          url={`${process.env.REACT_APP_BRAINTRUST_URL}/?hsLang=en`}>
          <Logo className="navigation__logo__img" />
        </Link>
        <div className="navigation__items">
          <div
            id="nav-for-talent"
            className="navigation__item">
            <div className="navigation__item__display">
              <div className="navigation__item__display__link">
                <Label type="primary" size="small" font="medium">For Talent</Label>
              </div>
              <ArrowDownIcon className="navigation__item__icon" />
            </div>
            <SubMenu data={forTalent} property="for-talent" />
          </div>
          <div
            id="nav-for-enterprise"
            className="navigation__item">
            <div className="navigation__item__display">
              <div className="navigation__item__display__link">
                <Label type="primary" size="small" font="medium">For Enterprises</Label>
              </div>
              <ArrowDownIcon className="navigation__item__icon" />
            </div>
            <SubMenu data={forEnterprises} property="for-enterprise" />
          </div>
          <div
            id="nav-about"
            className="navigation__item">
            <div className="navigation__item__display">
              <div className="navigation__item__display__link">
                <Label type="primary" size="small" font="medium">About</Label>
              </div>
              <ArrowDownIcon className="navigation__item__icon" />
            </div>
            <SubMenu data={about} property="about" />
          </div>
          <div className="navigation__item">
            <Link
              url={`${process.env.REACT_APP_BRAINTRUST_URL}/governance`}
              size="small">
              <Label type="primary" font="medium">Governance</Label>
            </Link>
          </div>
          <div className="navigation__item">
            <Link
              url={`${process.env.REACT_APP_BRAINTRUST_URL}/blog`}
              size="small">
              <Label type="primary" font="medium">Blog</Label>
            </Link>
          </div>
          <div className="navigation__item">
            <Link
              url={`${process.env.REACT_APP_BRAINTRUST_APP_URL}`}
              size="small">
              <Label type="primary" font="medium">Login</Label>
            </Link>
          </div>
          <div className='navigation_buttons md'>
            <div className="navigation__item">
              <Button
                border="round"
                type="primary"
                size="small"
                styles="btn-ht"
                onClick={gotoTalent}>
                Apply to join
              </Button>
            </div>
            <div className="navigation__item">
              <Button
                border="round"
                type="white"
                size="small"
                styles="btn-ht"
                onClick={gotoTalent}>
                Hire talent
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
