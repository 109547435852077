import React from 'react';

import ProjectMetrics from './ProjectMetrics';
import TalentJobMetrics from './TalentJobMetrics';

export default function Metrics({data}) {
  const avg_project_size = data ? data.average_project_size : null;
  const avg_project_duration = data ? data.average_project_duration : null;
  const total_jobs = data ? data.total_jobs : null;
  const total_talent = data ? data.total_talent : null;
  const total_proposals = data ? data.total_proposals : null;
  const students = data ? data.academy_students : null;

  return (
    <div className="dashboard__metrics">
      <ProjectMetrics
        avg_project_size={avg_project_size}
        avg_project_duration={avg_project_duration}
      />
      <TalentJobMetrics
        total_jobs={total_jobs}
        total_talent={total_talent}
        total_proposals={total_proposals}
        students={students}
      />
    </div>
  )
}
