import React from 'react';
import { Legend, PieChart, Pie, Cell } from 'recharts';
import { Pointer } from '../../assets/svgs';
import Label from '../Label';
import { numberFormatter } from '../../util';
import { useWindowSize } from '../../hooks';

const TOKEN_ALLOCATION = [
  {
    name: 'Coinlist Sale',
    value: 5,
    fill: '#D6E1F7',
  },
  {
    name: 'Early Contributors',
    value: 19,
    fill: '#F5D582',
  },
  {
    name: 'Early Token Purchasers',
    value: 22,
    fill: '#F4BE92',
  },
  {
    name: 'Community Incentives and Rewards',
    value: 54,
    fill: '#FBFBC2',
    stroke: '#E0E0E0',
    strokeWidth: '1',
  }
];

export default function TokenAllocationChart() {
  const windowSize = useWindowSize();
  const isTablet = windowSize.width > 640;

  return (
    <PieChart height={245} width={isTablet ? 400 : 330}>
      <Pie
        data={TOKEN_ALLOCATION}
        isAnimationActive={false}
        startAngle={88}
        cy={100}
        endAngle={360 + 88}
        outerRadius='100%'
        dataKey="value">
          {TOKEN_ALLOCATION.map(({ fill, stroke, strokeWidth = 0 }, index) => (
              <Cell
                key={`cell-${index}`}
                fill={fill}
                stroke={stroke}
                strokeWidth={strokeWidth} />
          ))}
      </Pie>
      <Legend
        layout="vertical"
        verticalAlign="middle"
        align="right"
        width={isTablet ? 195 : 190}
        content={<TokenAllocationList />} />
    </PieChart>
  )
}

const TokenAllocationList = () => {
  const items = [...TOKEN_ALLOCATION];

  return (
    <>
      <div className="allocation-chart-legend__label">
        <Pointer className="allocation-chart-legend__label-pointer" />
        <Label type="primary" size="large" font="medium">
          {numberFormatter(135000000)}<br /> BTRST
        </Label>
      </div>
      <ul className="allocation-chart-legend">
        {items.reverse().map(({ fill, name, value }) => (
          <li key={name} className="allocation-chart-legend__item">
            <span className="allocation-chart-legend__color" style={{ backgroundColor: fill }} />
            <Label variant="primary" size="medium" font="medium" className="allocation-chart-legend__value">{value}%</Label>
            <p className="allocation-chart-legend__name">{name}</p>
          </li>
        ))}
      </ul>
    </>
  )
}
