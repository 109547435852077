import React, { useState } from 'react';
// import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';

import Button from '../Button';
import Display from '../Display';
import Label from '../Label';
import Title from '../Title';

import { numberWithCommas } from '../../util';
import { BlackDetailIcon, CloseBlackIcon } from '../../assets/svgs';

export default function TalentJobMetrics({total_jobs, total_talent, total_proposals, students}) {
  const [showIntro, setShowIntro] = useState(false);

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  // const GraphChart = ({data}) => (
  //   <ResponsiveContainer width="110%" height={100}>
  //     <LineChart data={data} margin={{left: 0, top: 30, bottom: 30, right: 0}}>
  //       <Line
  //         type="monotone"
  //         dataKey="value"
  //         stroke="#F79B5C"
  //         strokeWidth={3}
  //         dot={false}
  //         activeDot={false}
  //         mar
  //       />
  //       <XAxis
  //         dataKey="date"
  //         tickLine={false}
  //         axisLine={false}
  //         minTickGap={-200}
  //         dy={10}
  //         dx={0}
  //         tick={t => {
  //           const {index, x, y, payload} = t;
  //           return (
  //             <g transform={`translate(${index === 0 ? x : x - 40},${y})`}>
  //               <text x={0} y={30}
  //                  textAnchor="start"
  //                  fill="#666">{payload.value}
  //               </text>
  //             </g>
  //           )
  //         }}
  //       />
  //       <YAxis
  //         type="number"
  //         tick={false}
  //         axisLine={false}
  //       />
  //       <Tooltip />
  //     </LineChart>
  //   </ResponsiveContainer>
  // );

  return (
    <div
      className="dashboard__metrics__right"
      data-aos="fade-up"
      data-aos-delay="50">
      <div
        className={`dashboard__metrics__right__main ${`dashboard__metrics__right__main__${showIntro ? 'hide' : 'show'}`}`}>
        <div className="dashboard__metrics__right__header">
          <Title type="primary" size="medium" font="medium">
            Talent and job metrics.
          </Title>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__metrics__right__content">
          <div className="dashboard__metrics__right__content__item">
            <div className="dashboard__metrics__right__content__item__display">
              {total_jobs && (
                <Display type="primary" size="small" text={numberWithCommas(total_jobs)} />
              )}
              <Label
                type="primary"
                size="large"
                styles="dashboard__metrics__right__content__item__display__label">
                Total Jobs
              </Label>
            </div>
            {/* {graphData && graphData.total_jobs_graph_data && (
              <GraphChart data={graphData.total_jobs_graph_data} />
            )} */}
          </div>
          <div className="dashboard__metrics__right__content__item">
            <div className="dashboard__metrics__right__content__item__display">
              {total_talent && (
                <Display type="primary" size="small" text={numberWithCommas(total_talent)} />
              )}
              <Label
                type="primary"
                size="large"
                styles="dashboard__metrics__right__content__item__display__label">
                Total Talent
              </Label>
            </div>
            {/* {graphData && graphData.total_talents_graph_data && (
              <GraphChart data={graphData.total_talents_graph_data} />
            )} */}
          </div>
          <div className="dashboard__metrics__right__content__item">
            <div className="dashboard__metrics__right__content__item__display">
              {total_proposals && (
                <Display type="primary" size="small" text={numberWithCommas(total_proposals)} />
              )}
              <Label
                type="primary"
                size="large"
                styles="dashboard__metrics__right__content__item__display__label">
                Talent Applications
              </Label>
            </div>
            {/* {graphData && graphData.total_proposals_graph_data && (
              <GraphChart data={graphData.total_proposals_graph_data} />
            )} */}
          </div>
          <div className="dashboard__metrics__right__content__item">
            <div className="dashboard__metrics__right__content__item__display">
              {students && (
                <Display type="primary" size="small" text={numberWithCommas(students)} />
              )}
              <Label
                type="primary"
                size="large"
                styles="dashboard__metrics__right__content__item__display__label">
                Academy Students
              </Label>
            </div>
            {/* {graphData && graphData.academy_students_graph_data && (
              <GraphChart data={graphData.academy_students_graph_data} />
            )} */}
          </div>
        </div>
      </div>
      <div
        className={`dashboard__metrics__right__intro ${`dashboard__metrics__right__intro__${showIntro ? 'show' : 'hide'}`}`}>
        <div className="dashboard__metrics__right__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__metrics__right__intro__content">
          <div className="dashboard__metrics__right__intro__content__item">
            <Label type="primary" size="large" font="medium">
              Total Jobs
            </Label>
            <br />
            <Label type="primary" size="medium">
              The total number of jobs that have been posted on the Braintrust platform.
            </Label>
          </div>
          <div className="dashboard__metrics__right__intro__content__item">
            <Label type="primary" size="large" font="medium">
              Total Talent
            </Label>
            <br />
            <Label type="primary" size="medium">
              The total number of talent profiles on the Braintrust platform. This metric includes both verified and not-yet verified talent.
            </Label>
          </div>
          <div className="dashboard__metrics__right__intro__content__item">
            <Label type="primary" size="large" font="medium">
              Talent Applications
            </Label>
            <br />
            <Label type="primary" size="medium">
              The total number of aplications that have been submitted by Braintrust talent.
            </Label>
          </div>
          <div className="dashboard__metrics__right__intro__content__item">
            <Label type="primary" size="large" font="medium">
              Academy Students
            </Label>
            <br />
            <Label type="primary" size="medium">
              The number of unique Braintrust users who have enrolled in Braintrust Academy.
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
