import React, { useState } from 'react';
import Button from '../Button';
import Label from '../Label';
import Title from '../Title';
import TokenAllocationChart from './TokenAllocationChart';
import { CloseBlackIcon, BlackDetailIcon } from '../../assets/svgs';
import { numberFormatter } from '../../util';

export default function TokenAllocation({ circulatingSupply }) {
  const [showIntro, setShowIntro] = useState(false);
  const maxSupply = 250000000;

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  return (
    <div
      className="dashboard__allocation"
      data-aos="fade-up"
      data-aos-delay="50">
      <div className={`dashboard__allocation__main ${`dashboard__allocation__main__${showIntro ? 'hide' : 'show'}`}`}>
        <div className="dashboard__allocation__header">
          <Title type="primary" size="medium" font="medium">
            BTRST token supply and allocation.
          </Title>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        <Label variant="primary" size="large">
          Circulating supply: {new Intl.NumberFormat().format(circulatingSupply)} BTRST
        </Label>
        <div className="dashboard__allocation__content">
          <div>
            <Title variant="primary" size="larger" font="medium">
              {numberFormatter(maxSupply)}
            </Title>
            <Label variant="primary" size="large" font="medium">
              Total BTRST Tokens
            </Label>
            <Label variant="primary" size="medium">
              Fixed Supply
            </Label>
          </div>
          <TokenAllocationChart />
        </div>
      </div>
      <div className={`dashboard__allocation__intro ${`dashboard__allocation__intro__${showIntro ? 'show' : 'hide'}`}`}>
        <div className="dashboard__allocation__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashobard__wallet__intro__content">
          <div className="dashboard__allocation__intro__content__item">
            <Label type="primary" size="large" font="medium">
              BTRST allocation
            </Label>
          </div>
          <div className="dashboard__allocation__intro__action-container">
            <Label type="primary" size="medium">
              The allocation of total supply of BTRST tokens is spread across four groups.
              For additional allocation information and release schedules, please view Braintrust’s Tokenomics one-pager.
            </Label>
            <a href="https://www.usebraintrust.com/tokenomics?hsCtaTracking=cd61b923-d41c-42f3-be62-28d62322b00a%7C39ed5979-37e6-4dd4-8914-3279e901a610" target="_blank" className="btn-ht button button__white button__small button__round">
              View Tokenomics
            </a>
          </div>
          <div className="dashboard__allocation__intro__content__item">
            <Label type="primary" size="large" font="medium">
              BTRST tokens, fixed
            </Label>
          </div>
          <div className="dashboard__allocation__intro__action-container">
            <Label type="primary" size="medium">
              The total number of Braintrust tokens is finite. There is a cap of 250 million tokens of BTRST,
              and no more or less will ever exist. That means BTRST cannot be diluted.
              135 million tokens are reserved for community incentives.
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
