import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 120 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
        <defs>
        <style>{".cls-logo-icon-1{fill:currentColor;}"}</style>
      </defs>
        <path d="M0 29.2188L115.409 29.2188" stroke="white" stroke-width="5.8"/>
        <path d="M90.8531 56.6855L116 29.7172L90.8531 1.99983" stroke="white" stroke-width="5.8"/>
      </svg>
  )
}

export default SvgComponent;
